@import '/src/styles/_variables.scss';
@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Noto Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  line-height: 1.42857143;
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;

  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #9ebc3b;
  --secondary-color: #02365c;
  --light-bg: #f5f3ee;
  --light-gray: #f8f8f8;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: -0.4px;
}

a {
  color: #000;
}

.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2.5rem;
}

.InnerPage {
}

.dx-switch {
  .dx-switch-container {
    height: 2.8rem;
    border-radius: 2.5rem;
  }
  .dx-switch-handle {
    flex-basis: 2rem;
    height: 2rem;
    width: 2rem;
    margin-top: 3px;
    margin-right: 1px;
  }
  .dx-switch-handle:before {
    border-radius: 50%;
    background-color: $secondary-color;
  }
  &.dx-switch-off-value .dx-switch-handle::before {
    background-color: $secondary-color;
  }
  &.dx-switch-on-value .dx-switch-handle::before {
    background-color: $primary-color;
  }
  .dx-switch-off,
  .dx-switch-on {
    line-height: 28px;
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.dx-switch.dx-state-hover .dx-switch-container {
  border-color: $primary-color;
}
.dx-switch.dx-state-hover .dx-switch-handle::before {
  background-color: $primary-color;
}

// DATAGRID
// ------------------------------
#UsersList .dx-datagrid {
  width: 100%;
  padding: 3.5rem;
  border-radius: $br;
  // background-color: transparent;
}

#ShopsList .dx-datagrid {
  width: 100%;
  border-radius: $br;
}

.dx-datagrid {
  .dx-datagrid-header-panel {
    border: 0;
    padding-bottom: 1.5rem;
  }
  .dx-datagrid-header-panel .dx-toolbar-after {
    width: 100%;
    padding-left: 0;
  }

  // search
  .dx-datagrid-header-panel .dx-toolbar-after .dx-texteditor.dx-editor-outlined {
    border: 0;
  }
  .dx-datagrid-header-panel .dx-toolbar-after input {
    min-height: 4.5rem;
    background-color: $light-bg;
    border-radius: $br;
    border: 0;
  }

  .dx-datagrid-headers {
    // border-bottom: 10px solid $light-bg;
    border-bottom: 2px solid $light-bg;
  }
  .dx-datagrid-headers .dx-datagrid-table tbody tr:nth-child(odd) {
    background-color: #fff;
  }
  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border: 0;
  }
  .dx-datagrid-header-panel .dx-toolbar-after .dx-datagrid-search-panel {
    width: 100% !important;
    margin: 0;
  }
  // .dx-datagrid-headers,
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    vertical-align: middle;
    border: 0;
    padding-bottom: 1rem;
  }

  .dx-datagrid-headers .dx-datagrid-table tbody tr td {
    // padding-bottom: 1rem;
  }
  .dx-datagrid-headers .dx-datagrid-table .dx-datagrid-text-content {
    font-size: 1.2rem;
    line-height: 1.4;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .dx-datagrid-rowsview tbody tr.dx-column-lines {
    border-top: 1px solid $light-bg;
    // border-bottom: 1px solid $light-bg;
    // border-left: 5px solid #fff;
    transition: all 60ms ease-in-out;

    &:hover {
      background-color: rgba($primary-color, 0.04);
    }
  }
  .dx-datagrid-table tbody tr:nth-child(even) {
    // background-color: darken($light-bg, 2);
    // background-color: rgba(0, 89, 255, 0.05);
    // background-color: rgba(#826800, 0.035);
    background-color: rgba(0, 65, 186, 0.05);
    // background-color: #02a1db;
  }
  .dx-datagrid-table tbody tr.dx-footer-row {
    background-color: #fff;
  }
  .dx-datagrid-table tbody tr td {
    vertical-align: middle;

    border-left: 0;
    border-right: 0;
    padding: 1.75rem 2rem;

    // &:first-child {
    //   border-top-left-radius: $br;
    //   border-bottom-left-radius: $br;
    // }
    // &.dx-last-data-cell {
    //   border-top-right-radius: $br;
    //   border-bottom-right-radius: $br;
    // }
    // &:last-child {
    //   border-top-right-radius: $br;
    //   border-bottom-right-radius: $br;
    // }

    .pill {
      line-height: 1;
      padding: 4px 10px;
      border-radius: 7px;
    }
    .pill.totalNetProfit {
      font-weight: 500;
      color: darken($primary-color, 20);
      background-color: rgba($primary-color, 0.15);
    }
  }

  .dx-datagrid-pager {
    background-color: $light-bg;
    border: 0;
    padding-top: 2.5rem;

    .dx-page-sizes .dx-selection {
      background-color: $primary-color;
    }
    .dx-pages .dx-page,
    .dx-page-sizes .dx-page-size {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $light-bg2;
      border-radius: $br;
      aspect-ratio: 1 / 1;
      min-height: 4rem;
      min-width: 4rem;
    }
    .dx-info {
      font-size: 1.3rem;
    }
    .dx-page-indexes .dx-selection {
      font-weight: 600;
      background-color: $primary-color;
      border-color: $primary-color;
    }
    .dx-page-indexes .dx-navigate-button:before {
      color: #000;
    }

    .dx-page-sizes {
    }
  }

  .dx-datagrid-total-footer {
    // background-color: $light-bg2;
    // border-bottom: 1px solid $primary-color;
    border-top: 15px solid $light-bg;

    .dx-datagrid-content {
      padding: 0;
    }
    .dx-datagrid-summary-item {
      color: #000;
    }
  }

  .dx-header-filter-empty {
  }
}
.datagrid-container {
  width: 100%;
}

#UsersList .dx-datagrid .dx-datagrid-pager {
  background-color: #fff;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border: 0 !important;
}

.dx-datagrid-header-panel {
  padding: 1.5rem;
}
.dx-datagrid-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item {
  text-align: right;
}

// DATEPICKER
// ------------------------------
.DatePicker .react-date-picker {
  width: 100%;
}

.react-date-picker {
  .react-date-picker__inputGroup__input:invalid {
    background-color: transparent;
  }

  .react-calendar__tile--now {
    color: $white;
    background-color: $dark-bg !important;
  }

  .react-date-picker__wrapper {
    border-radius: $br;
    border: 1px solid $input-border-color;
    border-radius: $br-input;
    padding: 0.5rem;
  }
  .react-date-picker__inputGroup {
    font-size: 1.5rem;
    cursor: pointer;
    text-align: center;
    min-width: 11rem;
    height: calc($inputHeight - 1.25rem);
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
  .react-date-picker__calendar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: $light-blue;
    // border-radius: $br-input;
    // aspect-ratio: 1 / 1;
    margin-right: 0.5rem;
  }
  .react-date-picker__inputGroup__divider {
    padding-left: 0.15rem;
    padding-right: 0.15rem;
  }

  // header
  .react-date-picker__calendar {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: $br;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.075);
  }
  .react-date-picker__calendar .react-calendar__navigation {
    background-color: $light-gray;
    display: flex;
    text-align: center;
    border-radius: $br;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
  }
  .react-calendar__tile {
    border-radius: $br;
  }
  .react-calendar__tile--active {
    background-color: $primary-color;
  }
  .react-calendar__tile--now {
    background-color: $light-gray;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
  }
  .react-calendar__month-view__days {
    row-gap: 1.5rem;
    padding-bottom: 1rem;
  }

  .react-calendar {
    font-family: inherit;
    border: 0;
  }
}

// SELECT
// ------------------------------
.sr-select__menu {
  // z-index: 99999999;
}
.sr-select__control {
  // input {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  // }
  // z-index: 9999;

  .sr-select__multi-value {
    background-color: rgba(0, 65, 186, 0.1);
  }

  .sr-select__multi-value__label {
    white-space: normal;
  }
}

.input-label {
  display: block;
  margin-bottom: 0.7rem;
}

// SELECT DEVEXTREME
.dx-dropdownbox.dx-textbox {
  input {
    min-height: 45px;
  }

  border-color: $input-border-color;
  border-radius: $br;

  // input {
  // padding-top: 0.35rem;
  // padding-bottom: 0.35rem;
  // }
}

.dx-popup-normal .dx-empty-message {
  text-align: center;
  padding: 1.5rem;
  background-color: $light-bg;
  border-radius: $br;
  margin-top: 0.5rem;
}

// MODALS
// ------------------------------
.modal-content {
  border-radius: $br-lg;
  // padding: 1.5rem 2.5rem;

  .modal-header {
    padding: 2rem 2.5rem 2rem 2.5rem;
    border: 0;

    .modal-title {
      font-size: 1.8rem;
      font-weight: 800;
    }
  }

  .modal-body {
    line-height: 1.6;
    background-color: $light-bg;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    border-radius: $br;
    border: 0;
  }

  .btn {
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1rem 2.5rem;
  }

  .modal-footer {
    background-color: $light-bg;
    justify-content: center;
    gap: 1rem;
    padding: 1.75rem 2.5rem 2.5rem 2.5rem;
    border-bottom-left-radius: $br-lg;
    border-bottom-right-radius: $br-lg;
    border: 0;
  }
  .modal-footer .btn-secondary {
    color: #000;
    background-color: transparent;
    border: 1px solid $secondary-color;
  }
  .modal-footer .btn-primary {
    color: #000;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

// reset reboot css
a {
  text-decoration: none;
}
ul {
  padding: 0;
}
