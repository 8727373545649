// $primary-color: #9ebc3b;
$primary-color: #88d464;
$primary-light: lighten(#88d464, 20);
$secondary-color: #02365c;
// $light-secondary: #8ad1ce;
$light-secondary: #e0e7f3;
$light-bg: #f4f4f6;
$light-bg2: darken($light-bg, 10);
$light-gray: #eeeeee;
$light-blue: rgba(0, 65, 186, 0.1);
$secondary-blue: #88d1ce;
$dark-bg: #1e1e1e;

$white: #fff;

$input-border-color: #c0c0c0;

$text-gray-dark: #2a2a2a;
$text-gray-light: #939393;

$br: 0.5rem;
$br-input: 0.5rem;
$br-sm: 0.35rem;
$br-lg: calc($br * 4);

$inputHeight: 4.5rem;

$appPadding: 3.5rem;

$inner_site_width: 1580px;
$boxed_width: 1440px;
$form_width: 900px;

$transition-1: all 300ms ease-in-out;
$transition-2: all 450ms ease-in-out;

$box-shadow-1: 12.6px 11.9px 26.8px -13px rgba(0, 0, 0, 0.003),
  21.9px 20.6px 51.1px -13px rgba(0, 0, 0, 0.006), 31.4px 29.6px 75px -13px rgba(0, 0, 0, 0.008),
  43.3px 40.9px 102.1px -13px rgba(0, 0, 0, 0.01), 61.3px 57.9px 138.9px -13px rgba(0, 0, 0, 0.013),
  106px 100px 201px -13px rgba(0, 0, 0, 0.02);

$box-shadow-2: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
$box-shadow-3: rgba(0, 0, 0, 0.15) 0px 20px 60px -12px, rgba(0, 0, 0, 0.2) 0px 8px 36px -18px;

@mixin breakpoint($point) {
  @if $point == desktop-ultra {
    @media only screen and (min-width: 1601px) {
      @content;
    }
  } @else if $point == desktop-large {
    @media only screen and (max-width: 1600px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  } @else if $point == desktop-small {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  } @else if $point == tablet-large {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $point == tablet-portrait {
    @media only screen and (max-width: 820px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == phone-large {
    @media only screen and (max-width: 640px) {
      @content;
    }
  } @else if $point == phone {
    @media only screen and (max-width: 480px) {
      @content;
    }
  } @else if $point == phone-small {
    @media only screen and (max-width: 320px) {
      @content;
    }
  } @else {
    @media only screen and ($point) {
      @content;
    }
  }
}
