@import '/src/styles/_variables.scss';

.Btn {
  width: auto;
  height: $inputHeight;
  padding: 0 3.5rem;
  /* -webkit-appearance: none;
    appearance: none; */
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border-radius: $br;

  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.BtnIcon {
  width: auto;
  height: 49px;
  padding: 0 13px;
  /* -webkit-appearance: none;
    appearance: none; */
  border: 1px solid #55aaff;
  background-color: #55aaff;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.Btn:hover,
.BtnIcon:hover {
  border: 1px solid #55aaff;
  background-color: #4f99e4;
}

.BtnDanger {
  width: auto;
  height: $inputHeight;
  padding: 0 20px;
  /* -webkit-appearance: none;
    appearance: none; */
  border: 1px solid #e2aeae;
  background-color: #e2aeae;
  color: #793333;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.BtnDangerIcon {
  width: auto;
  height: $inputHeight;
  padding: 0 13px;
  /* -webkit-appearance: none;
    appearance: none; */
  border: 1px solid #e2aeae;
  background-color: #e2aeae;
  color: #793333;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.BtnDanger:hover,
.BtnDangerIcon:hover {
  border: 1px solid #e2aeae;
  background-color: #da9898;
}
