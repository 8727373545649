@import '/src/styles/_variables.scss';

#PageHeader {
  width: 100%;
  background-color: $white;
  height: 6.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
}

.HeaderContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $appPadding;
  padding-right: $appPadding;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.HeaderContainer h1 {
  font-size: 1.8rem;
  margin: 0;
}

.HeaderLeft {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.WelcomeUser {
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 0.15rem;
}

.WelcomeDate {
  font-size: 1.2rem;
  font-weight: 300;
  // color: $text-gray-light;
  background-color: $light-blue;
  border-radius: 5px;
  padding: 2px 10px;
}

.LogoMobile {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.03rem;
  line-height: 1.4;
}
