@import '/src/styles/variables';

.PageSidebar {
  flex: 0 0 300px;
  // width: 300px;
  // width: 100%;
  background-color: $dark-bg;
  padding: 2.5rem;
  box-shadow: $box-shadow-1;
  transition: padding 0.1s ease-in-out, transform 450ms ease-in-out, width 450ms ease-in-out,
    flex 450ms ease-in-out;
  overflow: hidden;
}

.IsMobile {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 999999;
  transform: translateX(-300px);
}

.ToggleMobile {
  transform: translateX(0px);
}

.SidebarContent {
  height: 100%;

  .SidebarTop {
    position: relative;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    margin-bottom: 3.5rem;

    &._isToggled {
      justify-content: center;
    }

    .SidebarLogo {
      padding-top: 3.5rem;
    }
    .SidebarLogo h3 {
      font-size: 2.2rem;
      font-weight: 600;
      letter-spacing: -0.8px;
      color: $white;
      margin-top: 0;
    }
    .SidebarToggle {
      color: $white;
      font-size: 2rem;
      cursor: pointer;
    }
    .SidebarToggle svg {
      vertical-align: middle;
    }
  }

  .SidebarClose {
    position: absolute;
    right: 0;
    top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $light-bg2;
    cursor: pointer;
    z-index: 9;
  }

  .SidebarMain {
    height: calc(100% - 5rem - 8rem);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .SidebarBottom {
    height: 8rem;
  }
}
