.Error {
  margin-top: 6px;
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: baseline;
}
.ErrorIcon {
  color: #c32b2b;
  font-size: 15px;
  min-width: 15px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}
.ErrorText {
  color: #c32b2b;
  font-size: 13px;
}
