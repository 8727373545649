@import '/src/styles/app';
@import '../../styles/_variables.scss';

.NavItemTitle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  // margin-bottom: 0.5rem;

  .NavItemTitleText {
    flex: 1;
    color: $white;
  }
  .NavItemTitleIcon {
    font-size: 2rem;
    @include flex-full-center;
    color: $primary-color;
  }
  .NavItemTitleArrow {
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.5);
  }
}

.NavItemTitle.Active .NavItemTitleText {
  color: #88d1ce;
}

.NavItemLabel {
  // padding: 1.5rem;
  // padding: 1.5rem 0;
  // padding-right: 0;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  margin-top: 1rem;
  // background-color: rgba(255, 255, 255, 0.035);
  border-radius: $br;
  // margin-bottom: 1rem;

  &._isClickable {
    cursor: pointer;
  }
  &._isToggled {
    display: none;
  }
}
.NavItemLabel h5 {
  // font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.5);
  padding: 1.5rem 0;
  margin: 0;
}

.NavItemLink {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: $br;
  padding: 1.25rem 1.5rem;
  margin-bottom: 0.5rem;

  &.NavItemLinkActive {
    // background-color: rgba(255, 255, 255, 0.05);
    // background-color: $primary-color;
    // border-left: 5px solid $primary-color;
    background-color: $primary-color;
  }
  &.NavItemLinkActive .NavItemTitle {
    // color: $primary-color;
    // color: $dark-bg;
    color: #000;
  }
  &.NavItemLinkActive .NavItemIcon {
    // color: $primary-color;
    color: $dark-bg;
  }

  .NavItemIcon {
    font-size: 2.4rem;
    @include flex-full-center;
    color: $text-gray-light;
  }
  .NavItemTitle {
    font-weight: 500;
    color: $text-gray-light;
    text-decoration: none;
  }
}

.NavItem:not(:last-child) {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}
.NavItem._isToggled .NavItemLink {
  height: 5rem;
  padding: 0.25rem 1.3rem;
  gap: 2rem;
}
