@import '/src/styles/_variables.scss';

.AccountCard {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  &._isToggled {
    gap: 2rem;
  }

  .CardLeft {
    flex: 0 0 4.2rem;
  }
  &._isToggled .CardLeft {
    flex: 0 0 5rem;
  }
  .CardRight {
    flex: 1;
  }

  .CardIcon {
    position: relative;
  }
  .CardIcon svg {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;

    font-size: 5rem;
    color: $white;
    border-radius: 50%;
    outline: 3.5px solid $text-gray-light;
    outline-offset: -4px;
  }
  .CardIcon .UserIsActive {
    position: absolute;
    right: 0.3rem;
    bottom: 0.3rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $primary-color;
    border: 1px solid #000;
  }

  .CardRight {
    color: $white;

    .AccountName {
      font-size: 1.6rem;
      font-weight: 600;
      padding-bottom: 0.15rem;
    }
    .AccountEmail {
      font-size: 1.25rem;
      color: $text-gray-light;
    }
    .AccountRole {
      font-size: 1.25rem;
      color: $text-gray-light;
    }
    .AccountInfo {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // gap: 0.5rem;
    }
  }
}
