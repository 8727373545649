@import '../../../styles/variables';

.LoginPage {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  display: flex;
  // flex-direction: column;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  gap: 3.5rem;

  /* background-image: url('../../../assets/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: $light-gray;
}
.LoginPage .LoginPageContainer {
  padding: 1.5rem;
}

.LoginHeader {
  font-size: 4.8rem;
  font-weight: bold;
  // color: $primary-color;
  text-align: center;
  margin-bottom: 1.5rem;
}

.LoginFooter {
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  color: $text-gray-light;
  /* background-color: #fff; */
  padding: 2.5rem 2.5rem;
}

.LoginMain {
  // text-align: center;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: $br;
  padding: 5rem 5rem;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1.4rem;
    font-weight: 400;
    color: $text-gray-light;
  }
}

.LoginForm {
  width: 100%;
  margin-top: 3rem;

  label {
    font-size: 1.3rem;
    color: $text-gray-dark;
    text-align: left;
    padding-bottom: 0;
  }
}

.LoginForm > *:not(:last-child) {
  padding-bottom: 1.5rem;
}

.LoginForm button {
  min-height: 5.5rem;
  margin-top: 2rem;
}

@include breakpoint(desktop-small) {
  .LoginPage {
    height: 100%;
    // gap: 0;
    // background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .LoginMain {
    padding: 5rem 3.5rem;
  }
}
