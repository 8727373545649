@import '/src/styles/_variables.scss';

.MobileMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark-bg;

  font-size: 2.2rem;
  color: #fff;
  height: 4rem;
  aspect-ratio: 1 / 1;
  border-radius: $br;
  cursor: pointer;
}

.AccountBtn {
  display: flex;
  align-items: center;
  gap: 0.65rem;
  min-height: 4rem;
  background-color: $primary-color;
  padding: 0.75rem 1.5rem;
  border-radius: $br;
  cursor: pointer;

  .AccountIcon {
    font-size: 2.4rem;
  }
  .AccountText {
    font-size: 1.3rem;
    font-weight: 500;
    color: $text-gray-dark;
    letter-spacing: -0.01rem;
  }

  @media (max-width: 580px) {
    height: 4rem;
    padding: 1rem;
  }
}

.Dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 200px;
  width: 100%;
  background-color: $white;
  border-radius: $br;
  padding: 2rem 2.5rem;
  box-shadow: $box-shadow-3;
  z-index: 2;

  ul .DropdownItem {
    display: flex;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  ul .DropdownItem:first-child {
    padding-top: 0;
  }
  ul .DropdownItem:last-child {
    padding-bottom: 0;
  }
  ul .DropdownItem:not(:last-child) {
    border-bottom: 1px solid $light-bg;
  }

  .DropdownItem a {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .DropdownItem a .DropdownItemIcon {
    font-size: 2rem;

    svg {
      vertical-align: middle;
    }
  }
}

.Account {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
