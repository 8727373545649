@import '/src/styles/variables';

.AppContainer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.PageMain {
  height: 100vh;
  display: flex;
  gap: 0;
  // display: grid;
  // grid-template-columns: 300px 1fr;
}

.IsMobile {
  display: block;
}

.PageContent {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  background-color: $light-bg;
  overflow: hidden;
}

.PageContentInner {
  height: calc(100% - 6.5rem);
  padding: 3.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}
