@import '/src/styles/_variables.scss';

.InputTextContainer {
  height: $inputHeight;
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: $br-input;
  position: relative;
  overflow: hidden;
}
.InputTextContainerError {
  background-color: #c32b2b2c;
  border: 1px solid #c32b2b;
}
.InputText {
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 10px;
  font-size: 15px;
}
.InputTextLabel {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
}
.Eye {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
