.Separator {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.FullWidth {
  grid-column: span 2;
}
